import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/free-solid-svg-icons";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { UserInfo, ScheduleListScroll } from "../../recoil";
import { useRecoilValue, useRecoilState } from "recoil";
import { ROLES } from "../../features/Constant"
import * as HTTPManager from "../../features/HTTPManager";
import * as Utils from "../../features/Utils";
import useScript from "../../hooks/useScript";
import "../styles/CommonStyle.css";
import { Tooltip } from "react-tooltip";
import * as HTTPSettlementManager from "../../features/HTTPSettlementManager";
import { createWorker } from 'tesseract.js';


export default function SettlementPage() {
  useScript("https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js", "sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx");

  const navigate = useNavigate();
  const scrollPosition = window.pageYOffset;
  const [currentScroll, setCurrentScroll] = useRecoilState(ScheduleListScroll);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [tourList, setTourList] = useState([]);
  const [tourFilterList, setTourFilterList] = useState([]);

  const [toursBusInfo, setToursBusInfo] = useState([]);
  const [toursHotelInfo, setToursHotelInfo] = useState([]);
  const [toursHotelLogInfo, setToursHotelLogInfo] = useState([]);
  const [toursGuideInfo, setToursGuideInfo] = useState([]);
  const [toursGuideFilterInfo, setToursGuideFilterInfo] = useState([]);
  const [toursCheckListInfo, setToursCheckListInfo] = useState([]);
  const [toursFunds, setToursFunds] = useState([]);
  const [toursFiles, setToursFiles] = useState([]);

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);

  const [hotelList, setHotelList] = useState([]);
  const [busList, setBusList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [guideMemoList, setGuideMemoList] = useState([]);

  const userInfo = useRecoilValue(UserInfo);

  // 국가 버튼  
  const [phSelected, setPHSelected] = useState(true);
  const [inSelected, setINSelected] = useState(true);

  // 필터 관련 변수
  const filterNameList = ["번호 오름차순", "번호 내림차순", "시작일 빠른순", "시작일 느린순"];
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(2);
  const [showFilter, setShowFilter] = useState(false);

  const filterYearList = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, "닫기"];
  const [showYearList, setShowYearList] = useState(false);
  const filterMonthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, "닫기"];
  const [showMonthList, setShowMonthList] = useState(false);

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";

  let searchText = useRef("");
  let focusRef = useRef();

  const [uploadFileList, setUploadFileList] = useState([]);
  const [source, setSource] = useState("");
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState(0);
  const [selectedToursGuideId, setSelectedToursGuideId] = useState(0);
  const [ocrResult, setOCRResult] = useState("");

  const [sendFileType, setSendFileType] = useState("");

  // 가이드별 출력 위한 데이터
  const [guideNameList, setGuideNameList] = useState([]);

  // 가이드 데이터 업데이트 처리
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [updateDataType, setUpdateDataType] = useState("");
  const [updateDataValue, setUpdateDataValue] = useState("");

  const [totalBudget, setTotalBudget] = useState(0);

  async function convertImageToText(image) {
    const worker = await createWorker(["kor", "eng"]);

    (async () => {
      const {
        data: { text },
      } = await worker.recognize(image);

      setOCRResult(text);
    })();
  }

  const fileUpload = async (target, tourInfo, toursGuideId) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];

        setUploadFileList([
          {
            type: "settlement",
            file: file,
          },
        ]);

        let res = await HTTPSettlementManager.updateSettlementFile({
          accessToken: userInfo.accessToken,
          tourId: tourInfo.id,
          toursGuideId: toursGuideId,
          fileList: [
            {
              type: "settlement",
              file: file,
            },
          ],
        });

        if (res?.data?.result) {
          await fetchData();
        } else {
          alert("업로드 실패!");
        }

        setUploadFileList([]);
      }
    }
  };

  async function handleDownloadFile(fileURL, tourInfo, guideName) {
    let splitList = fileURL.split('/');
    let fileName = splitList[splitList.length - 1];
    let fileExt = fileName.split('.')[1];
    fileName = `${tourInfo.id} ${guideName} ${tourInfo.startDate.slice(5, 10).replace('-', '')} ~ ${tourInfo.endDate.slice(5, 10).replace('-', '')}.${fileExt}`

    fetch(fileURL, {
      method: 'GET',
      headers: {
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );

        document.body.appendChild(link);

        var answer = window.confirm(`Save ${fileName}?`);
        if (answer) {
          link.click();
          link.parentNode.removeChild(link);
        }
      });
  }

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);

        if (sendFileType === "receipt")
          convertImageToText(newUrl);

        setSource(newUrl);

        setUploadFileList([
          {
            type: sendFileType,
            file: file,
          },
        ]);
      }
    }
  };

  // 데이터 초기화
  async function initalize() {
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth());
  }

  // 서버 데이터 수신
  async function fetchData() {
    // 예약정보 요청
    let toursGuide = [];
    let userList = [];

    var response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data?.result !== undefined) {
      var sortList = [...response.data.result];
      sortList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      userList = sortList;
      setUserList(sortList);
    }

    response = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setToursBusInfo(response.data.toursBus);
      setToursHotelInfo(response.data.toursHotel);
      setToursHotelLogInfo(response.data.toursHotelLog);
      toursGuide = response.data.toursGuide;
      setToursGuideInfo(toursGuide);
      setToursCheckListInfo(response.data.toursCheckList);
      setToursFunds(response.data.toursFunds);
      setToursFiles(response.data.toursFiles);
    }

    // 투어정보 요청
    response = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let initTourList = [];
    if (response !== undefined) {
      setTourList(response.data.result);

      let initYear = selectedYear === 0 ? today.getFullYear() : selectedYear;
      let initMonth = selectedMonth === 0 ? today.getMonth() : selectedMonth;

      initTourList = response.data.result?.filter(tour => (new Date(tour.startDate.replace(" ", "T")).getFullYear() === initYear &&
        new Date(tour.startDate.replace(" ", "T")).getMonth() <= (initMonth - 1) && new Date(tour.startDate.replace(" ", "T")).getMonth() >= (initMonth - 2)));

      // 국가 선택 버튼 확인
      if (!phSelected) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (!inSelected) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      userList = sortList;
      filteringTour(initTourList, selectedFilterIndex, toursGuide, userList);
    }

    response = await HTTPManager.GetHotelList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setHotelList(response.data.hotelList);
    }

    response = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setBusList(response.data.busList);
    }

    response = await HTTPManager.GetGuideMemoForAdmin({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setGuideMemoList(response.data.guideMemoList);
    }
  }

  const [allowShow, setAllowShow] = useState(false);

  // useEffect (async () => {}, []) 형태 사용하지 말 것
  // 내부에서 하는 경우 async 함수를 정의해서 호출
  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await initalize();
          await fetchData();
          setAllowShow(true);
        }
      })
      .catch((err) => {
        alert(err.message);
      });

  }, []);

  function changeYearOrMonth(year, month) {
    // 선택된 연도 or 달만 표시
    let selectList = tourList.filter(tour =>
      (new Date(tour.startDate.replace(" ", "T")).getFullYear() === year && new Date(tour.startDate.replace(" ", "T")).getMonth() <= (month - 1)) && new Date(tour.startDate.replace(" ", "T")).getMonth() >= (month - 2)
    );

    // 필터 초기화
    filteringTour(selectList, 2, toursGuideInfo, userList);
    setPHSelected(true);
    setINSelected(true);
  }

  function filteringTour(list, index, toursGuide, userList) {
    if (list === undefined) return;

    let paxIn = 0;
    let paxPh = 0;

    list.map(tour => {
      if (tour.cancel === 1) return 0;

      // [, ( 기준 제거 숫자만 추출하기 위한 과정
      let firstItem = tour.pax?.split('[')[0];
      let secondItem = firstItem?.split('(')[0];
      let sum = secondItem?.split(/[\D]+/i)?.reduce((a, b) => a = a + Number(b), 0);

      if (sum !== undefined && tour.countryId === 2)
        paxIn += sum;

      if (sum !== undefined && tour.countryId === 3)
        paxPh += sum;
    });

    setSelectedFilterIndex(index);
    setShowFilter(false);
    setTourFilterList(list.filter(item => item.cancel !== 1));

    let toursGuideList = toursGuide.filter(guideInfo => {
      let tourInfo = list.filter(item => item.cancel !== 1).find(tour => tour.id === guideInfo.tourId);

      if (tourInfo !== undefined && tourInfo !== null) {
        let userInfo = userList?.find(user => user.id === guideInfo.guideId);
        // 정산완료 목록 제외
        if (userInfo !== undefined && userInfo !== null && guideInfo.accountFinish === null) {
          guideInfo.name = userInfo.name;
          guideInfo.nameEN = userInfo.nameEN;
          guideInfo.phoneNumber = userInfo.phoneNumber;
          guideInfo.birthDate = userInfo.birthDate;
          return guideInfo;
        }
      }
    });

    toursGuideList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
    setToursGuideFilterInfo(toursGuideList);

    let filterList = toursGuideList.map(item => {
      return item.name;
    });

    let uniqueList = [...new Set(filterList)];
    setGuideNameList(uniqueList);
  }

  const btnMoveMonthClass = "text-3xl pointerhover:hover:cursor-pointer px-4 py-1 pointerhover:hover:bg-gray-200 rounded-full focus:outline-none";

  function moveToRight() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month + 1 > 12) {
      setSelectedYear(selectedYear + 1);
      month = 0;
      year = year + 1;
    }

    setSelectedMonth(month + 1);
    changeYearOrMonth(year, month + 1);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  function moveToLeft() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month - 1 < 1) {
      setSelectedYear(selectedYear - 1);
      month = 13;
      year = year - 1;
    }

    setSelectedMonth(month - 1);
    changeYearOrMonth(year, month - 1);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  function roundDown(number, decimals) {
    decimals = decimals || 0;
    return (Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals));
  }

  function handleDoubleClick(tourId, toursGuideId, type) {
    setSelectedTourId(tourId);
    setSelectedToursGuideId(toursGuideId);
    setUpdateDataType(type);
    setShowUpdateDialog(true);
  }

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      let res = await HTTPSettlementManager.updateSettlementData({
        accessToken: userInfo.accessToken,
        tourId: selectedTourId,
        toursGuideId: selectedToursGuideId,
        dataType: updateDataType,
        dataValue: updateDataValue,
      });

      if (res?.data?.result) {
        setShowUpdateDialog(false);
        setUpdateDataType("");
        setUpdateDataValue("");
        await fetchData();
      } else {
        alert("업데이트 실패!");
      }
    }
  }

  const idColor = "#43A4F9";
  const phColor = "#FF3A3A";

  return (
    <>
      <div
        className="mt-2 md:mt-14 overflow-y-auto outline-none"
        style={{
          width: "100%",
          backgroundColor: "#fff",
          textAlign: "center",
          justifyContent: 'center',
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setShowYearList(false);
            setShowMonthList(false);
            e.preventDefault();
          }
        }}
        tabIndex={-1}
      >
        {
          allowShow &&
          <div style={{ width: '100%', height: "100%", alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
            <div style={{
              marginBottom: 5, display: "flex", width: "100%", justifyContent: "center", padding: 10,
            }}
            >
              <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
                moveToLeft();
              }} >
                <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#777" }} />
              </span>
              <div
                id="title"
                className="flex flex-row justify-center items-center text-[25px] select-none cursor-pointer">
                <div className="mr-[5px]" onClick={() => {
                  setShowYearList(true);
                }}>{selectedYear}년</div>
                <div onClick={() => {
                  setShowMonthList(true);
                }}>{selectedMonth}월</div>
              </div>
              <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
                moveToRight();
              }}>
                <FontAwesomeIcon icon={faCaretRight} style={{ color: "#777" }} />
              </span>
            </div>

            <div className="scrollRemoveBox flex flex-row mt-2 md:w-full overflow-auto select-none">
              <span
                className="flex-none items-center rounded-md px-2 py-1 ml-2 text-xs font-medium cursor-pointer"
                style={{
                  background: inSelected ? idColor : "#acacac",
                  color: inSelected ? "#FFFFFF" : "#fefefe",
                }}
                onClick={() => {
                  let initTourList = [];
                  // on > off
                  if (inSelected) {
                    initTourList = tourFilterList?.filter((item) => item.name !== "인도네시아");
                  }
                  // off > on  
                  else {
                    initTourList = tourList?.filter(
                      (item) =>
                        item.name === "인도네시아" &&
                        new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
                        new Date(item.startDate.replace(" ", "T")).getMonth() <= (selectedMonth - 1) && new Date(item.startDate.replace(" ", "T")).getMonth() >= (selectedMonth - 2)
                    );
                  }

                  // off and ph on
                  if (!inSelected && phSelected) {
                    initTourList = tourList?.filter(
                      (item) =>
                        new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
                        new Date(item.startDate.replace(" ", "T")).getMonth() <= (selectedMonth - 1) && new Date(item.startDate.replace(" ", "T")).getMonth() >= (selectedMonth - 2)
                    );
                  }

                  filteringTour(initTourList, selectedFilterIndex, toursGuideInfo, userList);
                  setINSelected(!inSelected);
                }}>
                {
                  "인도네시아 " + toursGuideFilterInfo?.filter((toursGuide) => tourFilterList.find(tour => (tour.id === toursGuide.tourId && tour.name === "인도네시아")) !== undefined).length
                }
              </span>

              <span
                className="flex-none items-center rounded-md px-2 py-1 ml-2 text-xs font-medium cursor-pointer select-none"
                style={{
                  background: phSelected ? phColor : "#acacac",
                  color: phSelected ? "#FFFFFF" : "#fefefe",
                }}
                onClick={() => {
                  let initTourList = []

                  if (phSelected) {
                    initTourList = tourFilterList?.filter((item) => item.name !== "필리핀");
                  }
                  else {
                    initTourList = tourList?.filter(
                      (item) =>
                        item.name === "필리핀" &&
                        new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
                        new Date(item.startDate.replace(" ", "T")).getMonth() <= (selectedMonth - 1) && new Date(item.startDate.replace(" ", "T")).getMonth() >= (selectedMonth - 2)
                    );
                  }

                  if (!phSelected && inSelected) {
                    initTourList = tourList?.filter(
                      (item) =>
                        new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
                        new Date(item.startDate.replace(" ", "T")).getMonth() <= (selectedMonth - 1) && new Date(item.startDate.replace(" ", "T")).getMonth() >= (selectedMonth - 2)
                    );
                  }

                  filteringTour(initTourList, selectedFilterIndex, toursGuideInfo, userList);
                  setPHSelected(!phSelected);
                }}>
                {
                  "필리핀 " + toursGuideFilterInfo?.filter((toursGuide) => tourFilterList.find(tour => (tour.id === toursGuide.tourId && tour.name === "필리핀")) !== undefined).length
                }
              </span>
            </div>

            <div className="flex flex-row w-full px-2 gap-1 justify-center font-bold">
              <div>인도네시아(회사입금 제외)</div>
              <div>
                {
                  Utils.formatNumber(toursGuideFilterInfo.reduce((a, currentValue) => {
                    let tourInfo = tourFilterList.find(tour => tour.id === currentValue.tourId);

                    if (tourInfo.name === "인도네시아") {
                      let guideFee = Number(currentValue.guideFee);
                      let shopIncentive = Number(currentValue.shopIncentive);
                      let tax = Number(guideFee) * 0.033 + (roundDown(shopIncentive * 0.03, -1) + roundDown(shopIncentive * 0.003, -1));
                      let sum = guideFee + shopIncentive - tax;
                      let preChargeChange = Number(currentValue.preChargeChange);

                      let totalSum = sum - preChargeChange;
                      if (totalSum > 0 && currentValue.receiptCheck === 1)
                        return a + Number(totalSum);
                      else
                        return a + 0;
                    } else {
                      return a + 0;
                    }
                  }, 0))
                }</div>
            </div>

            <div className="flex flex-row w-full px-2 gap-1 justify-center font-bold">
              <div>필리핀(회사입금 제외)</div>
              <div>
                {
                  Utils.formatNumber(toursGuideFilterInfo.reduce((a, currentValue) => {
                    let tourInfo = tourFilterList.find(tour => tour.id === currentValue.tourId);

                    if (tourInfo.name === "필리핀") {
                      let guideFee = Number(currentValue.guideFee);
                      let shopIncentive = Number(currentValue.shopIncentive);
                      let tax = Number(guideFee) * 0.033 + (roundDown(shopIncentive * 0.03, -1) + roundDown(shopIncentive * 0.003, -1));
                      let sum = guideFee + shopIncentive - tax;
                      let preChargeChange = Number(currentValue.preChargeChange);

                      let totalSum = sum - preChargeChange;
                      if (totalSum > 0 && currentValue.receiptCheck === 1)
                        return a + Number(totalSum);
                      else
                        return a + 0;
                    } else {
                      return a + 0;
                    }
                  }, 0))
                }</div>
            </div>

            <div className="flex flex-col w-full gap-10 p-2">
              {
                guideNameList.map(name => {
                  let guideTourList = toursGuideFilterInfo.filter(toursGuide => toursGuide.name === name);
                  let lastResult = 0;
                  let guideComplete = true;

                  return <div key={name} className="flex flex-col w-full text-[14px]">
                    <div className="grid grid-cols-12 w-full bg-[#FFFFFF] border-[1px] divide-x-[1px] divide-black border-black border-b-[1px]">
                      <div className="flex bg-[#D8E4BC] py-1 text-center justify-center">{name}</div>
                      <div></div>
                    </div>
                    <div className="grid grid-cols-12 w-full bg-[#C5D9F1] border-x-[1px] divide-x-[1px] divide-black border-black border-b-[1px]">
                      <div>NO</div>
                      <div>행사번호</div>
                      <div>인원</div>
                      <div>날짜</div>
                      <div>전도금</div>
                      <div className="font-bold">일비합계</div>
                      <div>쇼핑</div>
                      <div>인센티브</div>
                      <div>원천징수(3.3%)</div>
                      <div>합계</div>
                      <div>전도금잔액</div>
                      <div>총 합계</div>
                    </div>
                    {
                      guideTourList.map((data, index) => {
                        let tourInfo = tourFilterList.find(tour => tour.id === data.tourId);
                        let paxStr = tourInfo?.pax.split(/\[|\(|명|\+/);

                        let guideFee = Number(data.guideFee);
                        let shopIncentive = Number(data.shopIncentive);
                        let tax = Number(data.guideFee) * 0.033 + (roundDown(shopIncentive * 0.03, -1) + roundDown(shopIncentive * 0.003, -1));
                        let sum = guideFee + shopIncentive - tax;
                        let preChargeChange = Number(data.preChargeChange);

                        let totalSum = sum - preChargeChange;

                        if (data.receiptCheck !== null && data.receiptCheck === 1) {
                          lastResult += totalSum;
                        }

                        if (data.guideCheck === null || data.guideCheck === 0)
                          guideComplete = false;

                        return (
                          <div key={data.id}
                            className="grid grid-cols-12 w-full border-x-[1px] divide-x-[1px] divide-black border-black border-b-[1px]">
                            <div>{index + 1}</div>
                            <div>{data.tourId}</div>
                            <div>{paxStr?.length > 0 && paxStr[0]}</div>
                            <div>{data.startDate?.slice(5, 10).replace('-', '.')}~{data.endDate?.slice(5, 10).replace('-', '.')}</div>
                            <div className="cursor-pointer"
                              onDoubleClick={async () => {
                                handleDoubleClick(tourInfo.id, data.id, "preCharge");
                              }}>{Utils.formatNumber(data.preCharge)}</div>
                            <div className="font-bold cursor-pointer"
                              onDoubleClick={async () => {
                                handleDoubleClick(tourInfo.id, data.id, "guideFee");
                              }}
                            >{Utils.formatNumber(guideFee)}</div>
                            <div></div>
                            <div className="cursor-pointer"
                              onDoubleClick={async () => {
                                handleDoubleClick(tourInfo.id, data.id, "shopIncentive");
                              }}
                            >{Utils.formatNumber(shopIncentive)}</div>
                            <div>{Utils.formatNumber(tax)}</div>
                            <div>{Utils.formatNumber(sum)}</div>
                            <div
                              className="cursor-pointer"
                              style={{
                                color: preChargeChange < 0 ? "#FF0000" : "#000000"
                              }}
                              onDoubleClick={async () => {
                                handleDoubleClick(tourInfo.id, data.id, "preChargeChange");
                              }}
                            >{Utils.formatNumber(preChargeChange)}</div>
                            <div style={{
                              color: totalSum < 0 ? "#FF0000" : "#000000"
                            }}>{data.receiptCheck !== null && data.receiptCheck !== 0 && Utils.formatNumber(totalSum)}</div>
                          </div>
                        );
                      })
                    }

                    <div className="grid grid-flow-col grid-cols-4 w-full bg-[#FFFFFF] divide-x-[1px] divide-black border-black border-x-[1px]">
                      <div className="flex bg-[#FFFFFF] py-3 text-center justify-center font-bold">총 합 계</div>
                      <div
                        className="col-span-3 flex font-bold text-[15px] justify-center items-center"
                        style={{
                          background: guideComplete ? "#00B050" : "yellow",
                          color: guideComplete ? lastResult > 0 ? "#FFFFFF" : "#FF9fcf" : "#000000",
                        }}
                      >{Utils.formatNumber(lastResult)}</div>
                    </div>

                    <div className="grid grid-cols-4 w-full bg-[#FFFFFF] border-[1px] divide-x-[1px] divide-black border-black border-b-[1px]">
                      <div className="flex bg-[#FFFFFF] py-10 text-center justify-center">비 고</div>
                      <div className="col-span-3 flex p-1">수고하셨습니다.</div>
                    </div>
                  </div>
                })
              }
            </div>
            <div className="flex flex-col w-full gap-1 p-2">
              {
                toursGuideFilterInfo.map((guideData, index) => {
                  let tourInfo = tourFilterList.find(tour => tour.id === guideData.tourId);

                  if (tourInfo !== undefined)
                    return (
                      <div key={guideData.id} className="flex flex-row w-full p-2 rounded-lg gap-2" style={{
                        //background: index === "인도네시아" ? "#4171D6" : "#D31A45"
                        background: index % 2 === 0 ? "#F9F9F9" : "#DFDFDF"
                      }}>
                        <div className="font-bold">{tourInfo?.id}</div>
                        <div className="font-bold" style={{ color: tourInfo?.name === "인도네시아" ? "#4171D6" : "#D31A45" }}>{tourInfo?.name}</div>
                        <div className="flex h-fit bg-[#000000] text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer">{tourInfo?.startDate?.slice(2, 10).replaceAll('-', '.')}~{tourInfo?.endDate?.slice(2, 10).replaceAll('-', '.')}</div>

                        <div key={guideData.id} className="flex flex-row gap-1">
                          <div className="flex h-fit bg-[#7D59EA] text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer">
                            <a className={`guideInfo${guideData.id}`}>
                              {guideData.name}
                            </a>
                            <Tooltip anchorSelect={`.guideInfo${guideData.id}`} place="top">
                              생년월일 : {guideData.birthDate}
                            </Tooltip>
                          </div>

                          <div>
                            <input
                              className="hidden"
                              id={`settlement-file-${guideData.id}`}
                              type="file"
                              onChange={async (e) => {
                                await fileUpload(e.target, tourInfo, guideData.id);
                              }}
                            />
                            <label htmlFor={`settlement-file-${guideData.id}`}>
                              <div className="flex h-fit text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer bg-[#00000066]"
                              >파일업로드</div>
                            </label>
                          </div>

                          <div className="flex h-fit text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer bg-[#00000066]"
                            onClick={() => {
                              setSendFileType("receipt");
                              setSelectedTourId(tourInfo.id);
                              setSelectedToursGuideId(guideData.id);
                              setShowImageUpload(true);
                            }}>
                            영수증업로드
                          </div>

                          <div className="flex h-fit text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer bg-[#00000066]"
                            onClick={() => {
                              setSendFileType("tip");
                              setSelectedTourId(tourInfo.id);
                              setSelectedToursGuideId(guideData.id);
                              setShowImageUpload(true);
                            }}>
                            팁업로드
                          </div>

                          <div className="flex h-fit text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer bg-[#00000066]"
                            onClick={() => {
                              handleDoubleClick(tourInfo.id, guideData.id, "receiptCheck");
                            }}>
                            영수증확인
                          </div>

                          <div className="flex h-fit text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer bg-[#00000066]"
                            onClick={() => {
                              handleDoubleClick(tourInfo.id, guideData.id, "guideCheck");
                            }}>
                            가이드확인
                          </div>

                          <div className="flex h-fit text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer bg-[#00000066]"
                            onClick={() => {
                              handleDoubleClick(tourInfo.id, guideData.id, "accountFinish");
                            }}>
                            정산완료
                          </div>

                          {
                            (guideData.tipURL !== undefined && guideData.tipURL !== null) &&

                            <div
                              title={guideData.tipURL}
                              className="flex text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none p-1 px-2 font-bold bg-green-400"
                              onClick={() => {
                                Utils.showContent(guideData.tipURL);
                              }}>
                              팁지급내역
                            </div>
                          }

                          {
                            (guideData.receiptCheck !== undefined && guideData.receiptCheck !== null && guideData.receiptCheck !== 0) && <div
                              className="flex text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none p-1 px-2 font-bold bg-red-400">
                              영수증확인완료
                            </div>
                          }

                          {
                            (guideData.guideCheck !== undefined && guideData.guideCheck !== null && guideData.guideCheck !== 0) && <div
                              className="flex text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none p-1 px-2 font-bold bg-red-400">
                              가이드확인완료
                            </div>
                          }

                          {
                            (guideData.accountFinish !== undefined && guideData.accountFinish !== null) && <div
                              className="flex text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none p-1 px-2 font-bold bg-red-400">
                              정산완료
                            </div>
                          }

                          {
                            guideData.settlementURL !== undefined && guideData.settlementURL !== null && <div className="flex h-fit text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer bg-[black]"
                              onClick={async () => {
                                Utils.showContent(guideData.settlementURL);
                              }}
                            >정산파일</div>
                          }

                          {
                            (guideData.settlementURL !== undefined && guideData.settlementURL !== null) &&
                            <div className="flex h-fit text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer bg-[black]"
                              onClick={async (e) => {
                                handleDownloadFile(guideData.settlementURL, tourInfo, guideData.name);
                              }}
                            >다운로드</div>
                          }
                        </div>
                      </div>
                    )
                })
              }
            </div>
          </div>
        }
        {
          // 년 이동 처리
          showYearList &&
          <>
            <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
              <div className="relative w-full m-auto max-w-lg">
                <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  {
                    filterYearList.map((year, index) => {
                      return (
                        <div key={index}
                          className={index !== filterYearList.length - 1
                            ? `border-b ${liCommonCSSUserMenu}`
                            : `${liCommonCSSUserMenu}`
                          }>
                          <button
                            className={btnCommonCSSUserMenu}
                            onClick={() => {
                              if (typeof (year) === "number") {
                                setSelectedYear(year);
                                changeYearOrMonth(year, selectedMonth);

                                setShowYearList(false);
                              } else {
                                setShowYearList(false);
                              }
                            }}
                          >
                            {typeof (year) === "number" ? year + "년" : year}
                          </button>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        }
        {
          // 월 이동 처리
          showMonthList &&
          <>
            <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
              <div className="relative w-full m-auto max-w-lg">
                <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  {
                    filterMonthList.map((month, index) => {
                      return (
                        <div key={index}
                          className={index !== filterMonthList.length - 1
                            ? `border-b ${liCommonCSSUserMenu}`
                            : `${liCommonCSSUserMenu}`
                          }>
                          <button
                            className={btnCommonCSSUserMenu}
                            onClick={() => {
                              if (typeof (month) === "number") {
                                setSelectedMonth(month);
                                changeYearOrMonth(selectedYear, month);

                                setShowMonthList(false);
                              } else {
                                setShowMonthList(false);
                              }
                            }}
                          >
                            {typeof (month) === "number" ? month + "월" : month}
                          </button>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        }
        {
          showImageUpload ? (
            <>
              <div className="scrollRemoveBox mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none font-medium">
                <div className="relative w-full my-6 mx-auto max-w-lg">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#efefef] outline-none focus:outline-none">
                    <input
                      className="hidden"
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      capture="environment"
                      onChange={(e) => {
                        setOCRResult("");
                        handleCapture(e.target);
                      }}
                    />
                    <label htmlFor="icon-button-file">
                      <span className={"cursor-pointer px-2 py-1"}>
                        <h2 className="text-base font-bold select-none">사진선택</h2>
                      </span>
                    </label>
                    {
                      source &&
                      <div className="flex justify-center border-red-100 select-none">
                        <img src={source} alt={"snap"} className=""></img>
                      </div>
                    }
                    <div className="flex flex-wrap my-2 px-2">{ocrResult}</div>

                    <div className="flex flex-row">
                      {
                        source &&
                        <span className={"flex w-full cursor-pointer px-2 py-3 justify-center"}>
                          <div className={"select-none text-[deepskyblue] font-bold"} onClick={async () => {

                            if (sendFileType === "tip") {
                              let res = await HTTPSettlementManager.updateTipFile({
                                accessToken: userInfo.accessToken,
                                tourId: selectedTourId,
                                toursGuideId: selectedToursGuideId,
                                fileList: uploadFileList,
                              });

                              if (res?.data?.result) {
                                setShowImageUpload(false);
                                setSource("");
                                setOCRResult("");
                                setUploadFileList([]);
                                await fetchData();
                              } else {
                                alert("업로드 실패!");
                              }
                            } else if (sendFileType === "receipt") {
                              let res = await HTTPSettlementManager.updateReceiptFile({
                                accessToken: userInfo.accessToken,
                                tourId: selectedTourId,
                                toursGuideId: selectedToursGuideId,
                                fileList: uploadFileList,
                              });

                              if (res?.data?.result) {
                                setShowImageUpload(false);
                                setSource("");
                                setOCRResult("");
                                setUploadFileList([]);
                                await fetchData();
                              } else {
                                alert("업로드 실패!");
                              }
                            }
                          }}>업로드</div>
                        </span>
                      }
                      <span className={"flex w-full  cursor-pointer px-2 py-3 justify-center"}>
                        <div className={"select-none text-[red] font-bold"} onClick={() => {
                          setShowImageUpload(false);
                          setSource("");
                          setOCRResult("");
                          setUploadFileList([]);
                        }}>취소</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
            </>
          ) : null
        }
        {
          showUpdateDialog && <>
            <div className="scrollRemoveBox mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none font-medium">
              <div className="relative w-full my-6 mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#efefef] outline-none focus:outline-none">
                  <input
                    className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-4 pl-10 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mb-2"
                    type="text"
                    onChange={(e) => {
                      setUpdateDataValue(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                  <div className="flex flex-row">
                    <span className={"flex w-full cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[deepskyblue] font-bold"}
                        onClick={async () => {
                          let res = await HTTPSettlementManager.updateSettlementData({
                            accessToken: userInfo.accessToken,
                            tourId: selectedTourId,
                            toursGuideId: selectedToursGuideId,
                            dataType: updateDataType,
                            dataValue: updateDataValue,
                          });

                          if (res?.data?.result) {
                            setShowUpdateDialog(false);
                            setUpdateDataType("");
                            setUpdateDataValue("");
                            await fetchData();
                          } else {
                            alert("업데이트 실패!");
                          }
                        }}>업데이트</div>
                    </span>
                    <span className={"flex w-full  cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[red] font-bold"} onClick={() => {
                        setShowUpdateDialog(false);
                        setUpdateDataType("");
                        setUpdateDataValue("");
                      }}>취소</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        }
      </div>
    </>
  )
}