import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import * as Utils from '../../features/Utils';
import * as HTTPManager from "../../features/HTTPManager";
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../features/Constant";

export default function BusCalendar(Props) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const navigate = useNavigate();

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());

  const userInfo = useRecoilValue(UserInfo);

  var rows = [0, 1, 2, 3, 4, 5];
  var cols = [0, 1, 2, 3, 4, 5, 6];

  // 날짜 : getDate() 1 - 31
  // 요일 : getDay() 0 - 6
  var startDate = new Date(year, month, 1);
  var endDate = new Date(year, month + 1, 0);

  const [tourList, setTourList] = useState([]);
  const [tourFilterList, setTourFilterList] = useState([]);

  const [toursBusInfo, setToursBusInfo] = useState([]);
  const [toursHotelInfo, setToursHotelInfo] = useState([]);
  const [toursGuide, setToursGuideInfo] = useState([]);
  const [hoverId, setHoverId] = useState(0);

  const [busList, setBusList] = useState([]);

  async function fetchData() {
    var response = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined) {
      setTourList(response.data.result);
      setTourFilterList(response.data.result.filter(item => item.cancel !== 1 && (checkExistTour(year, month, item.startDate) || checkExistTour(year, month, item.endDate))));
    }

    response = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setToursBusInfo(response.data.toursBus);
      setToursHotelInfo(response.data.toursHotel);
      setToursGuideInfo(response.data.toursGuide);
    }

    response = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined && response.data.result) {
      setBusList(response.data.busList);
    }
  }

  // useEffect (async () => {}, []) 형태 사용하지 말 것
  // 내부에서 async 함수를 정의해서 호출
  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  function movingMonth(value) {
    let tmpYear = year;
    let tmpMonth = month;

    if (value > 11) {
      tmpYear = year + 1;
      tmpMonth = 0;
    } else if (value < 0) {
      tmpYear = year - 1;
      tmpMonth = 11;
    } else {
      tmpYear = year;
      tmpMonth = value;
    }

    setMonth(tmpMonth);
    setYear(tmpYear);

    setTourFilterList(tourList.filter(item => checkExistTour(tmpYear, tmpMonth, item.startDate) || checkExistTour(tmpYear, tmpMonth, item.endDate)));
  }

  function checkExistTour(year, month, tourDate) {
    // 현재 년, 월과 행사 정보의 년, 월 비교
    return new Date(tourDate.replace(" ", "T")).getFullYear() === year &&
      new Date(tourDate.replace(" ", "T")).getMonth() === (month);
  }

  function checkToday(value) {
    var result = true;
    // year
    if (year !== today.getFullYear()) result = false;

    //month
    if (month !== today.getMonth()) result = false;

    // day
    if (today.getDate() !== value - startDate.getDay() + 1) result = false;

    return result;
  }

  function checkExistDay(value) {
    var result = false;

    if (value > 0 && value <= endDate.getDate())
      result = true;

    return result;
  }

  // 일자별 투어 정보 목록
  var dayList = [];

  // 동일한 주차에서 가장 많은 투어 수 목록
  var rowMaxSizeList = [];

  // 최종 정렬된 투어 목록
  var baseList = [];

  // 데이터 구성
  function initRowArray() {
    let totalMax = 0;

    for (let i = 0; i < rows.length; i++) {
      let rowMax = 0;
      for (let j = 0; j < cols.length; j++) {
        if (checkExistDay(i * (cols.length) + j - startDate.getDay() + 1)) {
          let tourList = tourFilterList.filter(tour => isExistTour(tour.startDate, tour.endDate, (i * 7 + j - startDate.getDay() + 1)));
          let tourCount = tourList.length;
          let tmpList = tourList.map(tourInfo => tourInfo.id);
          dayList.push(tmpList);

          if (rowMax < tourCount)
            rowMax = tourCount;
        }
      }

      if (rowMax !== 0) {
        totalMax += rowMax;
        rowMaxSizeList.push(rowMax);
      }
    }

    if (totalMax > 0) {
      for (let i = 0; i < rows.length; i++) {
        let initRow = [];
        for (let j = 0; j < rowMaxSizeList[i]; j++) {
          initRow[j] = -1;
        }

        let prevList = Array.from(initRow);
        for (let k = 0; k < cols.length; k++) {
          if (checkExistDay(i * (cols.length) + k - startDate.getDay() + 1)) {
            let currList = dayList[i * cols.length + k - startDate.getDay()];

            if (currList === undefined) {
              baseList.push(initRow);
              // 깊은 복사
              prevList = Array.from(initRow);
            } else {
              {
                let tmpList = Array.from(prevList);

                // 1. 이전값이 현재값에 있으면 유지 없으면 -1
                for (let l = 0; l < tmpList.length; l++) {
                  let prevValue = tmpList[l];
                  let nextExist = currList.find(curr => curr === prevValue);
                  if (nextExist !== undefined) {
                    currList = currList.filter(curr => curr !== prevValue);
                  } else {
                    tmpList[l] = -1;
                  }
                }

                // 2. 남은 현재값 -1인 곳에 삽입
                for (let l = 0; l < tmpList.length; l++) {
                  if (tmpList[l] === -1 && currList.length > 0) {
                    tmpList[l] = currList[0];
                    currList = currList.slice(1, currList.length);
                  }
                }

                baseList.push(tmpList);
                prevList = Array.from(tmpList);
              }
            }
          }
        }
      }
    }
  }

  // 해당 날짜 투어 존재 여부 확인
  function isExistTour(start, end, day) {
    var result = false;

    // 2022-xx-xxT00:00:00 형태로 생성   
    // 위와 같은 형태로 생성하지 않으면 아이폰에서 제대로 나오지 않는다.
    var dateStr = year + "-" + String((month + 1)).padStart(2, "0") + "-" + String(day).padStart(2, "0") + "T00:00:00";
    var currDate = new Date(dateStr);

    if (currDate >= new Date(start.replace(" ", "T")) && currDate <= new Date(end.replace(" ", "T"))) {
      result = true;
    }

    return result;
  }

  function isStartEnd(date, day) {
    if (date === undefined) return false;

    var result = false;

    // 2022-xx-xxT00:00:00 형태로 생성   
    // 위와 같은 형태로 생성하지 않으면 아이폰에서 제대로 나오지 않는다.
    var dateStr = year + "-" + String((month + 1)).padStart(2, "0") + "-" + String(day).padStart(2, "0") + "T00:00:00";
    var currDate = new Date(dateStr);

    // 날짜 동등 비교는 === 을 사용하면 안됨
    if (currDate.getTime() === new Date(date.replace(" ", "T")).getTime()) {
      result = true;
    }

    return result;
  }

  initRowArray();

  // 월 이동 버튼 css
  const btnMoveMonthClass = "hover:text-[#0078D7]";


  const busColorList = ["#7c00fe", "#f9e400", "#ffaf00", "#8DCBE6", "#6c4e31", "#d7c3f1", "#00DFA2", "#FFACAC"];
  function getBackgroundColor(tourId) {
    let result = "#ffffff";
    let tourBusInfo = toursBusInfo.find(toursBus => toursBus.tourId === tourId);
    let busInfo = busList.find(bus => bus.id === tourBusInfo?.busId);

    switch (busInfo?.name) {
      case "글로벌":
        result = busColorList[0];
        break;
      case "모던":
        result = busColorList[1];
        break;
      case "베스트투어":
        result = busColorList[2];
        break;
      case "버스투어":
        result = busColorList[3];
        break;
      case "뉴글로벌A":
        result = busColorList[4];
        break;
      case "뉴글로벌B":
        result = busColorList[5];
        break;
      case "글로벌9":
        result = busColorList[6];
        break;
      case "우리렌트카":
        result = busColorList[7];
        break;
    }
    return result;
  }

  return (
    <div className="md:mt-14 h-full w-full text-center justify-center select-none z-[0] bg-[#3C3B38] text-white">
      <div className="flex justify-evenly">
        <div className="flex justify-center items-center text-[25px] mt-2">
          <span
            className={btnMoveMonthClass}
            onClick={() => {
              movingMonth(month - 1);
            }}
          >
            <FontAwesomeIcon icon={faCaretLeft} className="cursor-pointer" />
          </span>
          <div id="title" className="mx-[10px] select-none">
            {year}년 {month + 1}월
          </div>
          <span
            className={btnMoveMonthClass}
            onClick={() => {
              movingMonth(month + 1); // 부모 컴포넌트로 전달할 리코일 변수
            }}
          >
            <FontAwesomeIcon icon={faCaretRight} className="cursor-pointer" />
          </span>
        </div>
      </div>
      <div className="flex ml-4">
        <div className="bg-[#0078D7] text-white rounded-lg py-1 px-3 hover:cursor-pointer font-bold hover:opacity-60 select-none"
          onClick={() => {
            setYear(today.getFullYear());
            setMonth(today.getMonth());
          }}>
          오늘
        </div>
      </div>

      <div id="calendarHeader" className="flex">
        <div className="bg-[#3C3B38] text-red-500 flex-1 font-extrabold">일</div>
        <div className="bg-[#3C3B38] flex-1 text-white font-extrabold">월</div>
        <div className="bg-[#3C3B38] flex-1 text-white font-extrabold">화</div>
        <div className="bg-[#3C3B38] flex-1 text-white font-extrabold">
          수
        </div>
        <div className="bg-[#3C3B38] flex-1 text-white font-extrabold">
          목
        </div>
        <div className="bg-[#3C3B38] flex-1 text-white font-extrabold">
          금
        </div>
        <div className="bg-[#3C3B38] text-blue-500 flex-1 font-extrabold">토</div>
      </div>
      <div id="calendarBody" className="flex flex-col h-full">
        {
          //map 중첩되는 경우 중첩되는 모든 곳에서 return 처리 해야 함
          rows.map((row) => {
            if ((startDate.getDay() + endDate.getDate()) / cols.length > row) {
              return (
                <div key={"row" + row} className="flex w-full h-full">
                  {
                    // 달력 열 추가
                    cols.map((col) => {
                      return (
                        <div
                          key={"col" + (row * cols.length + col)}
                          className="flex w-full bg-[#3C3B38] text-[white]"
                        >
                          <span
                            className={
                              checkToday(row * cols.length + col)
                                ? `text-white bg-[#0078D7] font-bold z-20 w-full`
                                : `z-20 w-full`
                            }
                          >
                            <div className="flex justify-center mb-1">
                              {
                                checkExistDay(row * cols.length + col - startDate.getDay() + 1)
                                  ? row * cols.length + col - startDate.getDay() + 1 + "(" + tourFilterList.filter(tour => isExistTour(tour.startDate, tour.endDate, (row * cols.length + col - startDate.getDay() + 1))).length + ")"
                                  : ""
                              }
                            </div>
                            <div className="flex flex-col w-full gap-1 justify-center items-center">
                              {
                                checkExistDay(row * cols.length + col - startDate.getDay() + 1) &&
                                baseList[row * cols.length + col - startDate.getDay()]?.map((tourId, idx) => {
                                  let tourInfo = tourFilterList.find(tour => tour.id === tourId);

                                  let isStart = false;
                                  let isEnd = false;

                                  if (tourInfo !== undefined) {
                                    isStart = isStartEnd(tourInfo.startDate, (row * cols.length + col - startDate.getDay() + 1));
                                    isEnd = isStartEnd(tourInfo.endDate, (row * cols.length + col - startDate.getDay() + 1));
                                  }

                                  return (
                                    <div key={idx + "/" + tourId + "/" + (row * cols.length + col)}
                                      onMouseOver={() => {
                                        setHoverId(tourId);
                                      }}
                                      className="flex w-full justify-center"
                                      style={{
                                        background: tourId === -1 ? "#3C3B38" : tourId === hoverId ? "#ff0000" : getBackgroundColor(tourId),
                                        color: tourId === -1 ? "#3C3B38" : "#000000",
                                        borderTopLeftRadius: isStart ? "10px" : "0px",
                                        borderBottomLeftRadius: isStart ? "10px" : "0px",
                                        borderTopRightRadius: isEnd ? "10px" : "0px",
                                        borderBottomRightRadius: isEnd ? "10px" : "0px",
                                      }}
                                    >
                                      <div className="text-[10px]">{tourId}</div>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </span>
                        </div>
                      );
                    })
                  }
                </div>
              );
            }
            return null;
          })
        }
      </div>
    </div>
  );
}