import AIRASIA from './a_com_airasia.png';
import AIRASIAX from './a_com_airasia_x.png';
import AIRSEOUL from './a_com_airseoul.png';
import ASIANA from './a_com_asiana.png';
import CEBU from './a_com_ceb.png';
import ESTART from './a_com_esr.png';
import JINAIR from './a_com_jinair.png';
import JEJUAIR from './a_com_jja.png';
import KOREANAIR from './a_com_kal.png';
import PAL from './a_com_pal.png';
import QTR from './a_com_qtr.png';
import SIN from './a_com_sin.png';
import GA from './a_com_ga.png';
import CPA from './a_com_cpa.png';
import VN from './a_com_vn.png';
import MH from './a_com_mh.png';
import BI from './a_com_bi.png';
import OD from './a_com_od.png';
import TR from './a_com_tr.png';
import TG from './a_com_tg.png';
import VJ from './a_com_vj.png';
import GANGWON from './gangwon.png';
import MU from './a_com_echina.png';
import NX from './a_com_macau.png';

const iamges = {
  AIRASIA,
  AIRASIAX,
  AIRSEOUL,
  ASIANA,
  CEBU,
  ESTART,
  JINAIR,
  JEJUAIR,
  KOREANAIR,
  PAL,
  QTR,
  SIN,
  GA,
  CPA,
  VN,
  MH,
  BI,
  OD,
  TR,
  TG,
  VJ,
  GANGWON,
  MU,
  NX,
}

export default iamges;