import React, { useState, useEffect } from "react";
import { useRecoilState, } from "recoil";
import { ROLES } from "../../features/Constant";
import * as HTTPManager from "../../features/HTTPManager";
import { UserInfo } from "../../recoil";
import Calendar from "../Calendar";
import TourInfoItemGuide from "./TourInfoItemGuide";

const PageMain = () => {
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const [myTourList, setMyTourList] = useState([]);

  // 이슈 : ToursCardList에 현재 달과 다른 달의 투어 정보가 표시됨.
  // 재현경로 : 다른 달로 이동한 상태 > 다른 메뉴 페이지 확인 > 이 페이지로 복귀
  // 수정 : 전역 변수 > 지역변수
  // Calendar 컴포넌트에서 set함수 받아서 처리
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());

  const [userList, setUserList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [busList, setBusList] = useState([]);

  const [toursBusInfo, setToursBusInfo] = useState([]);
  const [toursHotelInfo, setToursHotelInfo] = useState([]);
  const [toursGuideInfo, setToursGuideInfo] = useState([]);
  const [guideMemoList, setGuideMemoList] = useState([]);

  async function fecthData() {
    if (userInfo.accessToken === undefined) return;

    let res = "";

    if (userInfo.rolesId === ROLES.LEADERGUIDE || userInfo.rolesId === ROLES.ADMIN) {
      res = await HTTPManager.GetUserList({
        accessToken: userInfo.accessToken,
      });

      setUserList(res.data.result);
    }

    res = await HTTPManager.GetMyToursList({
      accessToken: userInfo.accessToken,
      year,
      month,
    });

    if (res.data.accessToken) {
      setUserInfo((prev) => {
        const returnValue = { ...prev };
        returnValue.accessToken = res.data.accessToken;

        return { ...returnValue };
      });
    }

    // [확인필요] 같은 행사 중복 수신    
    setMyTourList(res.data.result);

    if (res.data !== undefined) {
      setToursBusInfo(res.data.toursBus);
      setToursHotelInfo(res.data.toursHotel);
      setToursGuideInfo(res.data.toursGuide);
    }

    res = await HTTPManager.GetHotelList({
      accessToken: userInfo.accessToken
    });

    if (res !== undefined) {
      setHotelList(res.data.hotelList);
    }

    res = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken
    });

    if (res !== undefined) {
      setBusList(res.data.busList);
    }

    res = await HTTPManager.GetGuideMemoForGuide({
      accessToken: userInfo.accessToken,
      guideId: userInfo.id,
    });

    if (res !== undefined) {
      setGuideMemoList(res.data.guideMemoList);
    }
  }

  useEffect(() => {
    fecthData();
  }, [userInfo, year, month, setUserInfo]);

  return (
    <div className="bg-gray-100 w-full h-full">
      <div className="flex flex-1">
        <Calendar
          myTourList={myTourList}
          setYear={setYear}
          setMonth={setMonth}
        />
      </div>
      <div className="bg-white flex flex-col px-4 md:px-1 pb-6">
        <div className="">
          <span className="flex flex-col gap-7 w-full">
            {myTourList?.length > 0 &&
              myTourList?.map((item, index) => {
                if (userInfo.rolesId === ROLES.LEADERGUIDE || userInfo.rolesId === ROLES.ADMIN)
                  return <TourInfoItemGuide key={index}
                    tour={item}
                    guide={toursGuideInfo}
                    guideName={userList.find(user => user.id === item.guideId)?.name}
                    hotel={toursHotelInfo.filter(hotelInfo => hotelInfo.tourId === item.tourId)}
                    hotelList={hotelList}
                    bus={toursBusInfo.filter(busInfo => busInfo.tourId === item.tourId)}
                    busList={busList}
                    index={index}
                    refresh={fecthData}
                    guideMemoList={guideMemoList?.filter(memoInfo => memoInfo.tourId === item.tourId)}
                  />;
                else
                  return <TourInfoItemGuide key={index}
                    tour={item}
                    guide={toursGuideInfo}
                    guideName={userInfo?.name}
                    hotel={toursHotelInfo.filter(hotelInfo => hotelInfo.tourId === item.tourId)}
                    hotelList={hotelList}
                    bus={toursBusInfo.filter(busInfo => busInfo.tourId === item.tourId)}
                    busList={busList}
                    index={index}
                    refresh={fecthData}
                    guideMemoList={guideMemoList?.filter(memoInfo => memoInfo.tourId === item.tourId)}
                  />;
              })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageMain;
