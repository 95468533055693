import React from "react";

const Community = () => {
  return (
    <div className="p-4 bg-gray-100 flex-1 md:mt-14">
      <div className="grid grid-cols-3 md:grid-cols-1 gap-5">
        <div className="border-4">커뮤니티</div>
      </div>
    </div>
  );
};

export default Community;
