import React, { useState } from "react";
import * as HTTPManager from "../../features/HTTPManager";
import * as StateList from "../../features/StateList";
import * as Utils from "../../features/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faTrash, } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faSpinner, faCloudDownload, } from "@fortawesome/free-solid-svg-icons";
import { TOURINFO_COLOR_SET_IN } from "../../features/Constant";
import Dropdown from "../Dropdown";
import ReservationHotelDialog from "./ReservationHotelDialog";
import ReservationBusDialog from "./ReservationBusDialog";
import ReservationGuideDialog from "./ReservationGuideDialog";
import ModifyDialog from "./ModifyDialog";
import ModifyFileDialog from "./ModifyFileDialog";
import 'react-toastify/dist/ReactToastify.css';
import TourInfoItemAddOn from "./TourInfoItemAddOn";
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import "./TourInfoItemStyle.css";
import "../../fonts/Font.css"
import { Tooltip } from "react-tooltip";
import images from '../../assets/images';
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

export default function TourInfoItem(Props) {
  const navigate = useNavigate();

  // 함수
  function setColor(country) {
    switch (country) {
      case "인도네시아":
        return TOURINFO_COLOR_SET_IN.IN;
      case "필리핀":
        return TOURINFO_COLOR_SET_IN.PH;
      default:
        return TOURINFO_COLOR_SET_IN.CANCEL;
    }
  }

  function checkExistTour(startDate, endDate) {
    if (today >= new Date(startDate.replace(" ", "T")) && today <= new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function checkCompleteTour(endDate) {
    if (today > new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function getMinusDday(startDate) {
    var dayCount = (new Date(startDate.replace(" ", "T")) - today) / 86400000;

    return dayCount;
  }

  function getPlusDday(startDate) {
    var dayCount = (today - new Date(startDate.replace(" ", "T"))) / 86400000 + 1;

    return dayCount;
  }

  const userInfo = useRecoilValue(UserInfo);

  // 변수
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // 전달 받은 정보
  let tourInfo = Props.data;
  let tourGuideInfo = Props.guide;
  let tourHotelInfo = Props.hotel;
  let toursHotelLogInfo = Props.hotelLog;
  let tourBusInfo = Props.bus;
  let tourCheckListInfo = Props.checkList;
  let toursFunds = Props.funds;

  let hotelList = Props.hotelist;
  let busList = Props.buslist;

  let guideMemoList = Props.guideMemoList;

  // 가공 정보
  let hotelTmpArray = tourHotelInfo?.filter(hotelInfo => hotelInfo.tourId === tourInfo.id).map(item => { return item.hotelId });
  let uniqueHotelList = [...new Set(hotelTmpArray)];
  let filterToursHotel = tourHotelInfo?.filter(hotelInfo => hotelInfo.tourId === tourInfo.id);

  let busTmpArray = tourBusInfo?.filter(busInfo => busInfo.tourId === tourInfo.id).map(item => { return item.busId });
  let uniqueBusList = [...new Set(busTmpArray)];
  let filterToursBus = tourBusInfo?.filter(busInfo => busInfo.tourId === tourInfo.id);

  // 색상정보
  let colorSet = tourInfo.cancel === 1 ? setColor("cancel") : setColor(tourInfo.name);

  // 상태 정보 변경 위한 변수
  let reservationState = 0;

  function setReservationState(value) {
    reservationState = value;
  }

  // 부가 정보 표시
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  // 예약창 팝업
  const [showReservationHotelDialog, setShowReservationHotelDialog] = useState(false);
  const [showReservationBusDialog, setShowReservationBusDialog] = useState(false);
  const [showReservationGuideDialog, setShowReservationGuideDialog] = useState(false);

  // 텍스트 자료 수정 팝업
  const [showModifyDialog, setShowModifyDialog] = useState(false);

  // 파일 수정 팝업
  const [showModifyFileDialog, setShowFileModifyDialog] = useState(false);

  let dday = checkExistTour(tourInfo.startDate, tourInfo.endDate) ? getPlusDday(tourInfo.startDate) + "일차" : (checkCompleteTour(tourInfo.endDate) ? "완료" : "D-" + getMinusDday(tourInfo.startDate));

  // 입국인원 입력 팝업
  const [showEnterPaxDialog, setShowEnterPaxDialog] = useState(false);
  const [enterPax, setEnterPax] = useState("");

  async function modifyEnterPax() {
    if (enterPax.length === 0) return;

    let res = await HTTPManager.ModifyTourEnterPaxInfo({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.id,
      param: {
        enterPax: enterPax,
      },
    });

    if (res?.data?.result) {
      setEnterPax("");
      setShowEnterPaxDialog(false);
      Props.refresh();
    } else {
      alert("수정 실패!");
    }
  }

  async function handleDownloadFile(fileURL, type) {
    let splitList = fileURL.split('/');
    let fileName = splitList[splitList.length - 1];
    let fileExt = fileName.split('.')[1];
    fileName = `[${tourInfo.id}] ${type}.${fileExt}`

    let res = await HTTPManager.GetOriginalFileName({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.id,
      type: type,
    });

    if (res.data !== undefined && res.data.result !== null) {
      fileName = res.data.result
    }

    fetch(fileURL, {
      method: 'GET',
      headers: {
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );

        document.body.appendChild(link);

        var answer = window.confirm(`Save ${fileName}?`);
        if (answer) {
          link.click();
          link.parentNode.removeChild(link);
        }
      });
  }

  function getToursHotelChangeType(value) {
    // 1 : 룸타입  2 : 체크인  4 : 체크아웃 8 : 삭제
    let res = []

    if ((1 & value) !== 0) {
      res.push("RoomType");
    }

    if ((2 & value) !== 0) {
      res.push("Check In");
    }

    if ((4 & value) !== 0) {
      res.push("Check Out");
    }

    if ((8 & value) !== 0) {
      res.push("삭제");
    }

    return res.toString();
  }

  function getFundUI(city, state) {
    let res = [];

    switch (city) {
      // 서울
      case 0:
        break;
      // 강원
      case 1:
        res.push(<img src={images.GANGWON} className="ml-2" key="GANGWON" />);
        break;
    }

    switch (state) {
      // 신청완료
      case 0:
        res.push(<div key={state} className={`state0 h-5 w-5 bg-red-500 rounded-full text-[white] text-[13px] font-bold cursor-pointer select-none`}><Tooltip anchorSelect={`.state0`} place="top">신청완료</Tooltip> </div>);
        break;
      // 지원금확정
      case 1:
        res.push(<div key={state} className={`state1 h-5 w-5 bg-yellow-400 rounded-full text-[white] text-[13px] font-bold cursor-pointer select-none`}><Tooltip anchorSelect={`.state1`} place="top">지원금확정</Tooltip> </div>);
        break;
      // 결과보고완료
      case 2:
        res.push(<div key={state} className={`state2 h-5 w-5 bg-green-600  rounded-full text-[white] text-[13px] font-bold cursor-pointer select-none`}><Tooltip anchorSelect={`.state2`} place="top">결과보고완료</Tooltip> </div>);
        break;
    }

    return res;
  }

  function getAirplaneLOGO(flight) {
    let res = [];

    flight = flight?.toUpperCase();

    if (flight?.includes("KE")) {
      res.push(<img src={images.KOREANAIR} className="mr-2" key="KE" />);
    } else if (flight?.includes("OZ")) {
      res.push(<img src={images.ASIANA} className="mr-2" key="OZ" />);
    } else if (flight?.includes("SQ")) {
      res.push(<img src={images.SIN} className="mr-2" key="SQ" />);
    } else if (flight?.includes("PR")) {
      res.push(<img src={images.PAL} className="mr-2" key="PR" />);
    } else if (flight?.includes("GA")) {
      res.push(<img src={images.GA} className="mr-2" key="GA" />);
    } else if (flight?.includes("Z2")) {
      res.push(<img src={images.AIRASIA} className="mr-2" key="Z2" />);
    } else if (flight?.includes("D7")) {
      res.push(<img src={images.AIRASIAX} className="mr-2" key="D7" />);
    } else if (flight?.includes("5J")) {
      res.push(<img src={images.CEBU} className="mr-2" key="5J" />);
    } else if (flight?.includes("CX")) {
      res.push(<img src={images.CPA} className="mr-2" key="CX" />);
    } else if (flight?.includes("VN")) {
      res.push(<img src={images.VN} className="mr-2" key="VN" />);
    } else if (flight?.includes("MH")) {
      res.push(<img src={images.MH} className="mr-2" key="MH" />);
    } else if (flight?.includes("BI")) {
      res.push(<img src={images.BI} className="mr-2" key="BI" />);
    } else if (flight?.includes("OD")) {
      res.push(<img src={images.OD} className="mr-2" key="OD" />);
    } else if (flight?.includes("TR")) {
      res.push(<img src={images.TR} className="mr-2" key="TR" />);
    } else if (flight?.includes("TG")) {
      res.push(<img src={images.TG} className="mr-2" key="TG" />);
    } else if (flight?.includes("MU")) {
      res.push(<img src={images.MU} className="mr-2" key="MU" />);
    } else if (flight?.includes("NX")) {
      res.push(<img src={images.NX} className="mr-2" key="NX" />);
    }

    return res;
  }

  const clearEnterPax = tourInfo.enterPax !== null && tourInfo.enterPax !== undefined;
  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";
  const [showMenu, setShowMenu] = useState(false);
  const cancelMenu = tourInfo.cancel === 0 ? "행사취소" : "행사재개";
  const menuList = [tourInfo.id + "번", "정보수정", "파일업로드", "행사복사", "행사삭제", cancelMenu, "가이드용메모", "아이파크/신세계", "신라", "입국인원", "닫기"];

  const [showGuideMemo, setShowGuideMemo] = useState(false);
  const [guideMemo, setGuideMemo] = useState("");

  return (
    <div className="flex flex-col w-full h-full px-2 py-1 md:p-0 normal-case" style={{
      fontFamily: "Noto Sans KR"
    }}>
      <div className="flex flex-row w-full">
        <div className="flex flex-col w-full rounded" style={{
          background: colorSet.background,
          //borderBottom: `2px solid ${colorSet.name}`, 
        }}>
          {/* { 상단 영역 } */}
          <div>
            <div className="flex flex-col md:flex-col justify-between md:items-start pl-4 md:pl-1 pr-2 pb-1 my-2 items-center border-b-[2px] md:text-[13px]"
              style={{
                borderBottomColor: colorSet.horizontalLine,
              }}>
              <div className="flex w-full flex-col md:mb-1">
                <div className="flex flex-row items-center">
                  <div className={`flex flex-row gap-1 w-full mr-2 font-bold`} style={{ color: colorSet.name }}>
                    <div>[{tourInfo.id}] </div>
                    <div>{tourInfo.name} </div>
                    <div className="flex text-[white] font-bold bg-[black] px-2 py-[2px] rounded-full">{tourInfo.startDate.slice(5, 10).replace("-", ".")}~{tourInfo.endDate.slice(5, 10).replace("-", ".")}</div>
                    <div className="flex text-[white] font-bold bg-[black] px-2 py-[2px] rounded-full">{dday}</div>
                  </div>
                  <div className="flex h-full hover:text-[crimson] justify-end">
                    <div className="text-[20px] cursor-pointer text-center mx-4"
                      onClick={() => {
                        setShowMenu(true);
                      }}
                    >
                      {/* <div className="font-bold select-none">메뉴</div> */}
                      <FontAwesomeIcon icon={faGear} />
                    </div>
                  </div>
                </div>
                <div className="flex w-full text-[#858A94] font-bold text-start">
                  {
                    tourInfo.docNum &&
                    <div className="flex normal-case">
                      문서번호 : {tourInfo.docNum}
                    </div>
                  }
                </div>
                <div className="flex flex-row gap-1">
                  <div className={`flex mr-1 text-[black] font-bold ${clearEnterPax && "line-through"} mb-1`}>인원 : {tourInfo.pax}</div>
                  {
                    clearEnterPax && <div className="flex mr-2 text-[black] font-bold">실제입국 : {tourInfo.enterPax}</div>
                  }
                </div>
                {
                  tourInfo.itinerary !== undefined && tourInfo.itinerary !== null && <div className="flex text-[black] font-bold mb-1">{tourInfo.itinerary}</div>
                }
                {
                  (tourInfo.eta !== null || tourInfo.etd !== null) &&
                  <div className="flex text-left text-[skyblue] font-bold text-[13px] cursor-pointer items-center normal-case mb-1" >
                    {
                      getAirplaneLOGO(tourInfo.eta)
                    }
                    {
                      tourInfo.eta
                    }
                    /
                    {
                      tourInfo.etd
                    }
                  </div>
                }
              </div>
              <div className="flex w-full flex-row mb-1">
                {
                  tourGuideInfo.length > 0 ? tourGuideInfo.map(guide => {
                    return (
                      <div key={guide.id} className="bg-[#7D59EA] text-[white] text-[13px] md:text-[12px] font-bold rounded p-1 px-2 mr-1 select-none cursor-pointer" onClick={() => {
                        setShowReservationGuideDialog(!showReservationGuideDialog);
                      }}>
                        <a className={`guideInfo${guide.id}`}>
                          {guide.name} / {guide.nameEN} / {guide.phoneNumber !== undefined && guide.phoneNumber !== null && guide.phoneNumber}
                        </a>
                        <Tooltip anchorSelect={`.guideInfo${guide.id}`} place="top">
                          생년월일 : {guide.birthDate}
                        </Tooltip>
                      </div>
                    )
                  }) :
                    <div className="flex mr-[5px] text-[crimson] font-bold text-[16px] cursor-pointer"
                      onClick={() => {
                        setShowReservationGuideDialog(!showReservationBusDialog);
                      }} >
                      {"가이드(클릭)"}
                    </div>
                }
              </div>
              {
                guideMemoList?.filter(item => item.type === 0)?.length > 0 &&
                <div className="flex w-full flex-col items-start bg-[#e0e0e0] rounded p-3 font-bold text-[15px]">
                  <div>가이드 메모({guideMemoList?.filter(item => item.type === 0)?.length})</div>
                  <div className="flex flex-col">
                    {
                      guideMemoList?.filter(item => item.type === 0).map((memo, index) => {
                        return (
                          <div key={memo.id} className="text-start cursor-pointer" onClick={async () => {
                            let answer = window.confirm(
                              `${memo.contents}를 삭제 하시겠습니까?`
                            );

                            if (answer) {
                              let res = await HTTPManager.RemoveGuideMemo({
                                accessToken: userInfo.accessToken,
                                guideMemoId: memo.id,
                              });

                              if (res.data !== undefined && res.data !== null) {
                                if (res.data?.result === true)
                                  Props.refresh();
                              }
                            }
                          }}>
                            {index + 1}. {memo.contents}
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              }
              {
                guideMemoList?.filter(item => item.type === 1)?.length > 0 &&
                <div className="flex w-full flex-col items-start bg-[#e0e0e0] rounded p-3 font-bold text-[15px]">
                  <div>특이사항({guideMemoList?.filter(item => item.type === 1)?.length})</div>
                  <div className="flex flex-col">
                    {
                      guideMemoList?.filter(item => item.type === 1).map((memo, index) => {
                        return (
                          <div key={memo.id} className="text-start cursor-pointer" onClick={async () => {
                            //let answer = window.confirm(
                            //   `${memo.contents}를 삭제 하시겠습니까?`
                            // );

                            // if (answer) {
                            //   let res = await HTTPManager.RemoveGuideMemo({
                            //     accessToken: userInfo.accessToken,
                            //     guideMemoId: memo.id,
                            //   });

                            //   if (res.data !== undefined && res.data !== null) {
                            //     if (res.data?.result === true)
                            //       Props.refresh();
                            //   }
                            //}
                          }}>
                            {index + 1}. {memo.contents}
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              }
            </div>

          </div>
          {/* { 하단 영역 } */}
          <div className="flex flex-col justify-center">
            <div className="flex flex-row md:flex-col mb-4 items-center">
              <div className="flex flex-col w-full md:p-1 pl-3">
                <div className="flex flex-col mb-2">
                  {
                    uniqueHotelList.length > 0 ?
                      uniqueHotelList.map((item) => {
                        return (
                          <div key={item} className="flex flex-col mb-[2px] text-[20px] md:text-[15px] md:text-start">
                            {
                              // 필터링된 호텔 예약 정보 한번 더 호텔 id로 필터링
                              // 호텔명, 일정 한번 만 출력하기 위해서
                              filterToursHotel.filter(resHotel => resHotel.hotelId === item).map((resHotel, idx, row) => {
                                return (
                                  <div key={resHotel.id} className="flex flex-col mb-1 cursor-pointer">
                                    <div className="flex flex-row md:flex-col">
                                      <div className="flex flex-row items-center cursor-pointer text-[#fff] bg-[#000c] px-2 rounded"
                                        onClick={() => {
                                          setShowReservationHotelDialog(!showReservationHotelDialog);
                                        }}
                                      >
                                        <div className="flex flex-row font-bold mr-[5px]">
                                          <div className="mr-1">
                                            {hotelList.find(hotel => hotel.id === resHotel.hotelId)?.name}
                                            [{hotelList.find(hotel => hotel.id === resHotel.hotelId)?.address2}]
                                          </div>
                                          <div className="">
                                            ({resHotel.startDate?.slice(8, 10)}-{resHotel.endDate?.slice(8, 10)})
                                          </div>
                                        </div>
                                        <div className="font-medium">
                                          {resHotel.roomType}
                                        </div>
                                      </div>
                                      {

                                        <div className="mr-[5px] md:mb-[2px] font-bold">
                                          <Dropdown
                                            background={colorSet.background}
                                            title="상태"
                                            data={StateList.reservationStateList}
                                            setFunc={setReservationState}
                                            onClick={async () => {
                                              let res = await HTTPManager.ChangeStateToursHotel({
                                                tourId: tourInfo.id,
                                                hotelId: resHotel.hotelId,
                                                type: "state",
                                                state: reservationState,
                                              });

                                              if (res?.data) {
                                                //await fetchData();
                                              }
                                              else {
                                                alert("변경 실패!");
                                              }
                                            }}
                                            padding={"0px 10px 0px 10px"}
                                            initId={resHotel.state}
                                          />
                                        </div>
                                      }
                                      {
                                        resHotel.createdAt !== null &&
                                        <div className="flex flex-row text-left text-[16px] cursor-pointer mr-[5px] md:mb-[2px] ">
                                          <div className="flex bg-gradient-to-r from-red-500 to-orange-500 rounded-lg items-center">
                                            <span className={"cursor-pointer px-1 py-1 animate-spin"} />
                                            <div className="text-white flex flex-row items-center mr-2">
                                              <div className="text-[13px] font-bold mr-0.5">생성</div>
                                              <div className="text-[13px] font-bold">({resHotel.createdAt.slice(2, 20)})</div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      {
                                        resHotel.modifyAt !== null &&
                                        <div className="flex flex-row text-left text-[16px] cursor-pointer animate-pulse">
                                          <div className="flex bg-gradient-to-r from-green-500 to-lime-500 rounded-lg items-center">
                                            <span className={"cursor-pointer px-1 py-1 animate-spin"} />
                                            <div className="text-white flex flex-row items-center mr-2">
                                              <div className="text-[13px] font-bold mr-0.5">변경</div>
                                              <div className="text-[13px] font-bold">({resHotel.modifyAt.slice(2, 20)})</div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                    {
                                      toursHotelLogInfo.filter(logInfo => logInfo.toursHotelId === resHotel.id).length > 0 ? <div className="flex flex-col font-bold text-[15px] items-start bg-[#e0e0e0] p-3 mt-1 mr-3 md:mr-0 rounded">
                                        {
                                          "수정내역(" + toursHotelLogInfo.filter(logInfo => logInfo.toursHotelId === resHotel.id).length + ")"
                                        }
                                        {
                                          toursHotelLogInfo.filter(logInfo => logInfo.toursHotelId === resHotel.id).length > 0
                                          && toursHotelLogInfo.filter(logInfo => logInfo.toursHotelId === resHotel.id).map(logInfo => {
                                            return (
                                              <div key={"hotelLog" + logInfo.id}>
                                                [{logInfo.modifyAt}][{getToursHotelChangeType(logInfo.changeType)}] ({logInfo.startDate?.slice(8, 10)}-{logInfo.endDate?.slice(8, 10)}) {logInfo.roomType}
                                              </div>
                                            );
                                          })
                                        }
                                      </div> : null
                                    }

                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      }) :
                      <div className="mr-[5px] text-[crimson] font-bold text-[16px] text-start cursor-pointer"
                        onClick={() => {
                          setShowReservationHotelDialog(!showReservationHotelDialog);
                        }}
                      >
                        {"호텔예약(클릭)"}
                      </div>
                  }
                </div>
                <div className="flex flex-col">
                  {
                    uniqueBusList.length > 0 ? uniqueBusList.map((item) => {
                      return (
                        filterToursBus.filter(toursBus => toursBus.busId === item).map((toursBus, idx, row) => {
                          return (
                            <div key={toursBus.id} className="flex flex-col w-full md:flex-col cursor-pointer mb-[2px] text-[20px] md:text-[15px]">
                              <div className="flex flex-row items-center">
                                <div className="flex flex-row items-center cursor-pointer text-[#fff] bg-[#000c] px-2 rounded"
                                  onClick={() => {
                                    setShowReservationBusDialog(!showReservationBusDialog);
                                  }}
                                >
                                  <div className="mr-[5px] font-bold">
                                    {busList.find(bus => bus.id === toursBus.busId)?.name}
                                  </div>
                                  <div className="font-medium">
                                    {toursBus.busType}
                                  </div>
                                </div>
                                {
                                  idx === row.length - 1 &&
                                  <div className="mr-[5px] font-bold">
                                    <Dropdown
                                      background={colorSet.background}
                                      title="상태"
                                      data={StateList.reservationStateList}
                                      setFunc={setReservationState}
                                      onClick={async () => {
                                        let res = await HTTPManager.ChangeStateToursBus({
                                          tourId: tourInfo.id,
                                          busId: toursBus.busId,
                                          type: "state",
                                          state: reservationState,
                                        });

                                        if (res?.data) {
                                          //await fetchData();
                                        }
                                        else {
                                          alert("변경 실패!");
                                        }
                                      }}
                                      padding={"0px 10px 0px 10px"}
                                      initId={toursBus.state}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                          )
                        })
                      )
                    }) :
                      <div className="flex mr-[5px] text-[crimson] font-bold text-[16px] cursor-pointer"
                        onClick={() => {
                          setShowReservationBusDialog(!showReservationBusDialog);
                        }} >
                        {"버스예약(클릭)"}
                      </div>
                  }
                </div>
              </div>
            </div>

            {/* 파일 정보 */}
            <div className="scrollRemoveBox flex flex-row mb-1 px-3 md:p-1 w-full">
              <span
                title="Upload"
                className={"cursor-pointer px-2 py-1 rounded mr-2"}
                style={{ background: "crimson" }}
                onClick={() => {
                  setShowFileModifyDialog(true);
                }}>
                <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
              </span>
              <div className="flex-none w-[150px] text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1 mr-1"
                style={{ background: (tourInfo.logo !== undefined && tourInfo.logo !== null) ? colorSet.linkOnBackground : colorSet.linkOffBackground }}>
                <div className="flex flex-row items-center justify-between">
                  {
                    (tourInfo.logo !== undefined && tourInfo.logo !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          handleDownloadFile(tourInfo.logo, "logo");
                        }}>
                          <FontAwesomeIcon icon={faCloudDownload} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                  <div className="items-center w-full justify-center" onClick={() => {
                    Utils.showContent(tourInfo.logo);
                  }}>로고</div>
                  {
                    (tourInfo.logo !== undefined && tourInfo.logo !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          e.stopPropagation();

                          let answer = window.confirm(
                            "파일을 삭제 하시겠습니까?"
                          );

                          if (answer) {
                            await HTTPManager.RemoveTourFile({
                              accessToken: userInfo.accessToken,
                              id: tourInfo.id,
                              type: "logo"
                            });
                          }
                          Props.refresh();
                        }}>
                          <FontAwesomeIcon icon={faTrash} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                </div>
              </div>

              <div className="flex-none w-[150px] text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1 mr-1"
                style={{ background: (tourInfo.rooming !== undefined && tourInfo.rooming !== null) ? colorSet.linkOnBackground : colorSet.linkOffBackground }}>
                <div className="flex flex-row items-center justify-between">
                  {
                    (tourInfo.rooming !== undefined && tourInfo.rooming !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          handleDownloadFile(tourInfo.rooming, "rooming");
                        }}>
                          <FontAwesomeIcon icon={faCloudDownload} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                  <div className="items-center w-full justify-center" onClick={() => {
                    Utils.showContent(tourInfo.rooming);
                  }}>루밍</div>
                  {
                    (tourInfo.rooming !== undefined && tourInfo.rooming !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          e.stopPropagation();

                          let answer = window.confirm(
                            "파일을 삭제 하시겠습니까?"
                          );

                          if (answer) {
                            await HTTPManager.RemoveTourFile({
                              accessToken: userInfo.accessToken,
                              id: tourInfo.id,
                              type: "rooming"
                            });
                          }
                          Props.refresh();
                        }}>
                          <FontAwesomeIcon icon={faTrash} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                </div>
              </div>

              <div className="flex-none w-[150px] text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1 mr-1"
                style={{ background: (tourInfo.finalDocIN !== undefined && tourInfo.finalDocIN !== null) ? colorSet.linkOnBackground : colorSet.linkOffBackground }}>
                <div className="flex flex-row items-center justify-between">
                  {
                    (tourInfo.finalDocIN !== undefined && tourInfo.finalDocIN !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          handleDownloadFile(tourInfo.finalDocIN, "finalDocIN");
                        }}>
                          <FontAwesomeIcon icon={faCloudDownload} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                  <div className="items-center w-full justify-center" onClick={() => {
                    Utils.showContent(tourInfo.finalDocIN);
                  }}>파이널(ITS)</div>
                  {
                    (tourInfo.finalDocIN !== undefined && tourInfo.finalDocIN !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          e.stopPropagation();

                          let answer = window.confirm(
                            "파일을 삭제 하시겠습니까?"
                          );

                          if (answer) {
                            await HTTPManager.RemoveTourFile({
                              accessToken: userInfo.accessToken,
                              id: tourInfo.id,
                              type: "finalDocIN"
                            });
                          }
                          Props.refresh();
                        }}>
                          <FontAwesomeIcon icon={faTrash} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                </div>
              </div>

              <div className="flex-none w-[150px] text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1 mr-1"
                style={{ background: (tourInfo.finalDoc !== undefined && tourInfo.finalDoc !== null) ? "crimson" : colorSet.linkOffBackground }}>
                <div className="flex flex-row items-center justify-between">
                  {
                    (tourInfo.finalDoc !== undefined && tourInfo.finalDoc !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          handleDownloadFile(tourInfo.finalDoc, "finalDoc");
                        }}>
                          <FontAwesomeIcon icon={faCloudDownload} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                  <div className="items-center w-full justify-center" onClick={() => {
                    Utils.showContent(tourInfo.finalDoc);
                  }}>파이널(KTS)</div>
                  {
                    (tourInfo.finalDoc !== undefined && tourInfo.finalDoc !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          e.stopPropagation();

                          let answer = window.confirm(
                            "파일을 삭제 하시겠습니까?"
                          );

                          if (answer) {
                            await HTTPManager.RemoveTourFile({
                              accessToken: userInfo.accessToken,
                              id: tourInfo.id,
                              type: "finalDoc"
                            });
                          }
                          Props.refresh();
                        }}>
                          <FontAwesomeIcon icon={faTrash} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                </div>
              </div>

              <div className="flex-none w-[150px] text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1 mr-1"
                style={{ background: (tourInfo.invoice !== undefined && tourInfo.invoice !== null) ? colorSet.linkOnBackground : colorSet.linkOffBackground }}>
                <div className="flex flex-row items-center justify-between">
                  {
                    (tourInfo.invoice !== undefined && tourInfo.invoice !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          handleDownloadFile(tourInfo.invoice, "invoice");
                        }}>
                          <FontAwesomeIcon icon={faCloudDownload} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                  <div className="items-center w-full justify-center" onClick={() => {
                    Utils.showContent(tourInfo.invoice);
                  }}>인보이스</div>
                  {
                    (tourInfo.invoice !== undefined && tourInfo.invoice !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          e.stopPropagation();

                          let answer = window.confirm(
                            "파일을 삭제 하시겠습니까?"
                          );

                          if (answer) {
                            await HTTPManager.RemoveTourFile({
                              accessToken: userInfo.accessToken,
                              id: tourInfo.id,
                              type: "invoice"
                            });
                          }
                          Props.refresh();
                        }}>
                          <FontAwesomeIcon icon={faTrash} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                </div>
              </div>

              <div className="flex-none w-[150px] text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1 mr-1"
                style={{ background: (tourInfo.scheduleKO !== undefined && tourInfo.scheduleKO !== null) ? colorSet.linkOnBackground : colorSet.linkOffBackground }}>
                <div className="flex flex-row items-center justify-between">
                  {
                    (tourInfo.scheduleKO !== undefined && tourInfo.scheduleKO !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          handleDownloadFile(tourInfo.scheduleKO, "scheduleKO");
                        }}>
                          <FontAwesomeIcon icon={faCloudDownload} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                  <div className="items-center w-full justify-center" onClick={() => {
                    Utils.showContent(tourInfo.scheduleKO);
                  }}>행지</div>
                  {
                    (tourInfo.scheduleKO !== undefined && tourInfo.scheduleKO !== null) ?
                      <div className="rounded-full w-[25px]">
                        <span className={"cursor-pointer"} onClick={async (e) => {
                          e.stopPropagation();

                          let answer = window.confirm(
                            "파일을 삭제 하시겠습니까?"
                          );

                          if (answer) {
                            await HTTPManager.RemoveTourFile({
                              accessToken: userInfo.accessToken,
                              id: tourInfo.id,
                              type: "scheduleKO"
                            });
                          }
                          Props.refresh();
                        }}>
                          <FontAwesomeIcon icon={faTrash} className="text-[white] hover:text-[#CFCFCF]" />
                        </span>
                      </div> :
                      <div></div>
                  }
                </div>
              </div>

            </div>
            <div className="flex flex-row justify-between pl-3 p-1 md:pl-1 mb-1">
              <div className="flex flex-row">
                {
                  tourInfo.name === "필리핀" &&
                  <div
                    title={tourInfo.payment}
                    className="flex text-[white] items-center justify-center rounded-full cursor-pointer text-[14px] select-none p-1 px-2 mr-[5px] font-medium"
                    style={{ background: (tourInfo.payment !== undefined && tourInfo.payment !== null) ? "#7D59EA" : "#7D59EA77" }}>
                    페이먼트
                  </div>
                }
                <div
                  className="flex text-[white] items-center justify-center rounded-full cursor-pointer text-[14px] select-none p-1 px-2 mr-[1px] font-medium"
                  onClick={() => {
                    setShowModifyDialog(true);
                  }}
                  style={{
                    background: (tourInfo.dutyFreeShop !== undefined && tourInfo.dutyFreeShop !== null) ? "#EB9C00" : "#EB9C0077"
                  }}>
                  {(tourInfo.dutyFreeShop !== undefined && tourInfo.dutyFreeShop !== null) ? tourInfo.dutyFreeShop : "면세점등록필요"}
                </div>
              </div>
            </div>
          </div>
          {
            (tourInfo.memo !== undefined && tourInfo.memo !== null) &&
            <div className="flex text-left text-[crimson] font-bold text-[16px] cursor-pointer p-2 normal-case"
              onClick={() => {
                setShowModifyDialog(true);
              }} >
              {tourInfo.memo}
            </div>
          }
          <div className="flex flex-row md:flex-col justify-between items-center">
            <div className="flex flex-row items-center gap-2">
              {
                getFundUI(toursFunds[0]?.cityId, toursFunds[0]?.state)
              }
            </div>
            <div className="flex flex-row md:flex-col mb-1 md:ml-1">
              {
                (tourInfo.createdAt !== undefined && tourInfo.createdAt !== null) &&
                <div className="flex flex-row text-left text-[16px] cursor-pointer mr-1 md:mb-1">
                  {/* [#FDD535] [#5BC6CC] [#48A6DC] [#989D9F] [#F06093] [#9580BB]*/}
                  <div className="flex bg-[#5BC6CC] rounded-lg items-center">
                    <span className={"cursor-pointer px-2 py-1"}>
                      <FontAwesomeIcon icon={faTag} style={{ color: "white" }} />
                    </span>
                    <div className="text-white flex flex-row items-center mr-2">
                      <div className="text-[13px] font-bold mr-0.5">생성</div>
                      <div className="text-[13px] font-bold">({tourInfo.createdAt.slice(2, 20)})</div>
                    </div>
                  </div>
                </div>
              }
              {
                (tourInfo.modifyValue !== undefined && tourInfo.modifyValue !== null) &&
                <div className="flex flex-row text-left text-[16px] cursor-pointer mr-1 md:mb-1">
                  {/* [#FDD535] [#5BC6CC] [#48A6DC] [#989D9F] [#F06093] [#9580BB]*/}
                  <div className="flex bg-[#9580BB] rounded-lg items-center">
                    <span className={"cursor-pointer px-2 py-1"}>
                      <FontAwesomeIcon icon={faTag} style={{ color: "white" }} />
                    </span>
                    <div className="text-white flex flex-row items-center mr-2">
                      <div className="text-[13px] font-bold mr-0.5">{tourInfo.modifyValue}</div>
                      <div className="text-[13px] font-bold">({tourInfo.modifyAt.slice(2, 20)})</div>
                    </div>
                  </div>
                </div>
              }
              {
                (tourInfo.roomingAt !== undefined && tourInfo.roomingAt !== null) &&
                <div className="flex flex-row text-left text-[16px] cursor-pointer mr-1 md:mb-1">
                  {/* [#FDD535] [#5BC6CC] [#48A6DC] [#989D9F] [#F06093] [#9580BB]*/}
                  <div className="flex bg-gradient-to-r from-[#1e90ff] to-[#00ced1] rounded-lg items-center">
                    <span className={"cursor-pointer px-2 py-1"}>
                      <FontAwesomeIcon icon={faTag} style={{ color: "white" }} />
                    </span>
                    <div className="text-white flex flex-row items-center mr-2">
                      <div className="text-[13px] font-bold mr-0.5">루밍</div>
                      <div className="text-[13px] font-bold">({tourInfo.roomingAt.slice(2, 20)})</div>
                    </div>
                  </div>
                </div>
              }
              {
                (tourInfo.roomingModifyAt !== undefined && tourInfo.roomingModifyAt !== null) &&
                <div className="flex flex-row text-left text-[16px] cursor-pointer animate-pulse">
                  {/* [#FDD535] [#5BC6CC] [#48A6DC] [#989D9F] [#F06093] [#9580BB]*/}
                  <div className="flex bg-gradient-to-r from-red-500 to-purple-500 rounded-lg items-center">
                    <span className={"cursor-pointer px-2 py-1 animate-spin"}>
                      <FontAwesomeIcon icon={faSpinner} style={{ color: "white" }} />
                    </span>
                    <div className="text-white flex flex-row items-center mr-2">
                      <div className="text-[13px] font-bold mr-0.5">루밍변경</div>
                      <div className="text-[13px] font-bold">({tourInfo.roomingModifyAt.slice(2, 20)})</div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {
        // 추가 메뉴
        showAdditionalInfo &&
        <TourInfoItemAddOn
          colorSet={colorSet}
          tourInfo={tourInfo}
          tourHotelInfo={tourHotelInfo}
          tourBusInfo={tourBusInfo}
          tourGuideInfo={tourGuideInfo}
          hotelList={hotelList}
          busList={busList}
          setShow={setShowAdditionalInfo}
          refresh={Props.refresh}
        />
      }
      {
        // 호텔 예약
        showReservationHotelDialog &&
        <ReservationHotelDialog
          setShow={setShowReservationHotelDialog}
          tourInfo={tourInfo}
          reservationInfo={tourHotelInfo.filter(item => item.tourId === tourInfo.id)}
          refresh={Props.refresh}
        />
      }
      {
        // 버스 예약
        showReservationBusDialog &&
        <ReservationBusDialog
          setShow={setShowReservationBusDialog}
          tourInfo={tourInfo}
          reservationInfo={tourBusInfo.filter(item => item.tourId === tourInfo.id)}
          refresh={Props.refresh}
        />
      }
      {
        // 가이드 예약
        showReservationGuideDialog &&
        <ReservationGuideDialog
          setShow={setShowReservationGuideDialog}
          tourInfo={tourInfo}
          reservationInfo={tourGuideInfo.filter(item => item.tourId === tourInfo.id)}
          refresh={Props.refresh}
        />
      }
      {
        // 투어 정보 수정
        showModifyDialog &&
        <ModifyDialog
          setShow={setShowModifyDialog}
          tourInfo={tourInfo}
          tourCheckListInfo={tourCheckListInfo.find(item => item.tourId === tourInfo.id)}
          refresh={Props.refresh}
        />
      }
      {
        // 파일업로드
        showModifyFileDialog &&
        <ModifyFileDialog
          setShow={setShowFileModifyDialog}
          tourInfo={tourInfo}
          refresh={Props.refresh}
          guideInfo={tourGuideInfo.filter(item => item.tourId === tourInfo.id)}
        />
      }
      {
        // 메뉴
        showMenu &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  menuList.map((menu, index) => {
                    return (
                      <div key={index}
                        className={index !== menuList.length - 1
                          ? `border-b ${liCommonCSSUserMenu}`
                          : `${liCommonCSSUserMenu}`
                        }>
                        <button
                          className={btnCommonCSSUserMenu}
                          onClick={async () => {
                            switch (menu) {
                              case "정보수정": {
                                setShowModifyDialog(true);
                                break;
                              }
                              case "파일업로드": {
                                setShowFileModifyDialog(true);
                                break;
                              }
                              case "행사복사": {
                                var answer = window.confirm(`${tourInfo.id}번을 복사하시겠습니까?`);
                                if (answer) {
                                  let res = await HTTPManager.CopyTour({
                                    accessToken: userInfo.accessToken,
                                    tourId: tourInfo.id,
                                  })

                                  if (res?.data !== undefined && res.data.result) {
                                    Props.refresh();
                                  }
                                  else {
                                    alert("복사 실패!");
                                  }
                                }
                                break;
                              }
                              case "행사삭제": {
                                let answer = window.confirm(`${tourInfo.id}번을 삭제하시겠습니까?`);
                                if (answer) {
                                  let res = await HTTPManager.DeleteTour({
                                    accessToken: userInfo.accessToken,
                                    tourId: tourInfo.id,
                                  })

                                  if (res?.data !== undefined && res.data.result) {
                                    Props.refresh();
                                  }
                                  else {
                                    alert("삭제 실패!");
                                  }
                                }
                                break;
                              }
                              case "행사재개":
                              case "행사취소": {
                                let infoStr = tourInfo.cancel === 0 ? "취소" : "재개";
                                let answer = window.confirm(`${tourInfo.id}번을 ${infoStr}하시겠습니까?`);
                                if (answer) {
                                  let res = await HTTPManager.UpdateTourState({
                                    accessToken: userInfo.accessToken,
                                    tourId: tourInfo.id,
                                    cancel: tourInfo.cancel,
                                  })

                                  if (res?.data !== undefined && res.data.result) {
                                    Props.refresh();
                                  }
                                  else {
                                    alert("실패!");
                                  }
                                }

                                break;
                              }
                              case "가이드용메모": {
                                setShowGuideMemo(true);
                                break;
                              }
                              case "아이파크/신세계": {
                                let copyData = tourGuideInfo.map(guide => {
                                  return ({
                                    name: guide.name,
                                    birth: guide.birthDate,
                                    phone: guide.phoneNumber,
                                    end: tourInfo.endDate.slice(4, 10),
                                    etd: tourInfo.etd,
                                    country: tourInfo.name
                                  })
                                });

                                navigator.clipboard.writeText(`가이드명: ${copyData[0]?.name}\n가이드 생년월일: ${copyData[0]?.birth}\n가이드 연락처: ${copyData[0]?.phone}\n출국날짜: ${copyData[0]?.end?.replaceAll('-', '')}\n출국편명: ${copyData[0]?.etd}\n출국시간: ${copyData[0]?.etd}\n국적: ${copyData[0]?.country}`);
                                alert('양식 복사 완료');
                                setShowMenu(false);
                                break;
                              }
                              case "신라": {
                                let copyData = tourGuideInfo.map(guide => {
                                  return ({
                                    name: guide.name,
                                    birth: guide.birthDate,
                                    phone: guide.phoneNumber,
                                    end: tourInfo.endDate.slice(0, 10),
                                    etd: tourInfo.etd,
                                    country: tourInfo.name
                                  })
                                });
                                navigator.clipboard.writeText(`여행사명 : 코리아트래블스토어\n가이드명: ${copyData[0]?.name}\n가이드 생년월일: ${copyData[0]?.birth}\n가이드 연락처: ${copyData[0]?.phone}\n방문예정일: \n출국일: ${copyData[0]?.end?.replaceAll('-', '')}\n출국편명: ${copyData[0]?.etd}\n출국시간: ${copyData[0]?.etd}\n국적 : ${copyData[0]?.country}`);
                                alert('양식 복사 완료');
                                setShowMenu(false);
                                break;
                              }
                              case "입국인원": {
                                setShowEnterPaxDialog(true);
                                break;
                              }
                              case "닫기":
                                setShowMenu(false);
                                break;
                            }
                          }}
                        >
                          {menu}
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        showGuideMemo && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none font-medium">
              <div className="relative w-full my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-center mt-5 rounded-t">
                    <h2 className="text-base">가이드용 메모</h2>
                  </div>
                  <div className="relative p-3 flex-auto">
                    <textarea
                      rows="10"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none"
                      placeholder={"내용입력"}
                      onChange={(e) => {
                        setGuideMemo(e.target.value);
                      }}
                    />
                  </div>

                  <div className="flex w-full items-center justify-center rounded-b border-t">
                    <button
                      className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                      type="button"
                      onClick={() => {
                        setShowGuideMemo(false);
                      }}
                    >
                      취소
                    </button>
                    <button
                      className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                      type="button"
                      onClick={async () => {
                        if (guideMemo.length > 0) {
                          let result = await HTTPManager.InsertGuideMemo({
                            accessToken: userInfo.accessToken,
                            param: {
                              tourId: tourInfo.id,
                              contents: guideMemo,
                              type: 0,
                            }
                          });

                          if (result.data !== undefined && result.data !== null) {
                            if (result.data?.result === true) {
                              alert('메모 등록 완료');
                              setShowGuideMemo(false);
                              Props.refresh();
                            }
                          }
                        }
                      }}
                    >
                      기록
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        )
      }
      {showEnterPaxDialog ? (
        <>
          <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none font-medium select-none">
            <div className="relative w-full my-6 mx-auto max-w-lg">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-center p-5 rounded-t">
                  <h2 className="text-base">입국인원</h2>
                </div>
                <div className="relative p-3 flex-auto">
                  <input
                    autoComplete="off"
                    className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                    placeholder={"입국인원"}
                    type="text"
                    name="text"
                    onChange={(e) => {
                      setEnterPax(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        modifyEnterPax();
                      }
                    }}
                    value={enterPax}
                  />
                </div>

                <div className="flex w-full items-center justify-center rounded-b border-t">
                  <button
                    className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      setShowEnterPaxDialog(false);
                    }}
                  >
                    {"취소"}
                  </button>
                  <button
                    className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                    type="button"
                    onClick={async () => {
                      modifyEnterPax();
                    }}
                  >
                    {"입력"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
        </>
      ) : null}
    </div >
  )
}
