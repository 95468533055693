import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  width: 100%;
  height: 45px;
  position: relative;
  border: 0;
  img {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

const Search = styled.input`
  border: 0;
  padding-left: 10px;
  background-color: #eaeaea;
  width: 100%;
  height: 100%;
  outline: none;
`;

const AutoSearchContainer = styled.div`
  z-index: 3;
  height: 30vh;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 45px;
  border: 1px solid;
  padding: 15px;
`;

const AutoSearchWrap = styled.ul`

`;

const AutoSearchData = styled.li`
  padding: 10px 8px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  z-index: 4;
  letter-spacing: 2px;
  &:hover {
    background-color: #cfcfcf;
    cursor: pointer;
  }
  position: relative;
  img {
    position: absolute;
    right: 5px;
    width: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

function AutoCompleteInput(Props) {
  const [keyword, setKeyword] = useState("");
  const [keyItemsFilter, setKeyItemsFilter] = useState([]);
  const keyItems = Props.list;

  const autoRef = useRef(null);
  const elmRefs = useRef();

  const onChangeData = (e) => {
    setKeyword(e.currentTarget.value);
    if (e.currentTarget.value === "")
      setKeyItemsFilter(keyItems);
    else
      setKeyItemsFilter(keyItems.filter(item => (item.name?.includes(e.currentTarget.value)) || (item.manager?.includes(e.currentTarget.value))));
  };

  const handleKeyArrow = (e) => {
    if (keyItems.length > 0) {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          if (index === -1) {
            setKeyItemsFilter(keyItems);
          }

          setIndex(index + 1);
          if (autoRef.current?.childElementCount <= index + 1) {
            elmRefs[0].current?.scrollIntoView({})
            setIndex(0);
          } else {
            elmRefs[index + 1].current?.scrollIntoView({ block: "center", })
          }
          break;
        case "ArrowUp":
          e.preventDefault();
          if (index === -1) {
            setKeyItemsFilter(keyItems);
          }

          setIndex(index - 1);

          if (index <= 0) {
            setIndex(keyItems.length - 1);
            elmRefs[keyItems.length - 1].current?.scrollIntoView({ block: "center", })
          } else {
            elmRefs[index - 1].current?.scrollIntoView({ block: "center", })
          }
          break;
        case "Enter":
          handleSelectedOption(keyItemsFilter[index]);
        case "Escape":
          setKeyword("");
          setIndex(-1);
          setKeyItemsFilter([]);
          break;
      }
    }
  }

  const [index, setIndex] = useState(-1);

  const initData = async () => {
    Props.list.forEach((element, index) => {
      elmRefs[index] = { current: null }
    });
  }

  useEffect(() => {
    initData();
  }, [Props.list, keyItems, keyItemsFilter])

  function handleSelectedOption(data) {
    if (Props.selectedType === "reservationBus") {
      Props.selectedFunc([...Props.selectedList, {
        id: data.id,
        name: data.name,
        area: data.area,
        busType: "",
        toursBusId: "",
      }]);
    } else if (Props.selectedType === "reservationHotel") {
      Props.selectedFunc([...Props.selectedList, {
        toursHotelId: "",
        id: data.id,
        address1: data.address1,
        address2: data.address2,
        name: data.name,
        startDate: Props.startDate,
        endDate: Props.endDate,
        roomType: "",
      }])
    } else if (Props.selectedType === "reservationEtaEtd") {
      Props.selectedFunc(data.content);
      Props.callback(data.content);
    } else if (Props.selectedType === "SELLER" || Props.selectedType === "BUYER" || Props.selectedType === "BANK" || Props.selectedType === "SIGN") {
      Props.selectedFunc(data.id);
    }

    setIndex(-1);
    setKeyItemsFilter([]);
  }

  function getTitle(data) {
    if (Props.selectedType === "reservationBus") {
      let result = data.id + " " + data.name + " " + data.area;
      if (data.name === "뉴글로벌")
        result += (" " + data.manager);
      return result;
    } else if (Props.selectedType === "reservationHotel") {
      return data.id + " " + data.address1 + " " + data.address2 + " " + data.name;
    } else if (Props.selectedType === "reservationEtaEtd") {
      return data.id + " " + data.content;
    } else if (Props.selectedType === "SELLER" || Props.selectedType === "BUYER") {
      return `[${data.id}] ${data.name} ${data.location} ${data.ceo} ${data.phoneNumber}`;
    } else if (Props.selectedType === "BANK") {
      return `[${data.id}] ${data.swiftcode} ${data.name} ${data.address} ${data.accountNumber} ${data.accountHolder}`;
    } else if (Props.selectedType === "SIGN") {
      return `[${data.id}] ${data.name}`;
    }
  }

  return (
    <SearchContainer>
      <Search value={keyword} onChange={onChangeData} onKeyDown={handleKeyArrow} placeholder={Props.label} />
      {keyItemsFilter.length > 0 && (
        <AutoSearchContainer style={{ overflow: 'scroll', textAlign: 'left', overflowX: 'hidden', }}>
          <AutoSearchWrap ref={autoRef}>
            {keyItemsFilter.map((data, idx) => {
              return (
                <AutoSearchData
                  ref={elmRefs[idx]}
                  key={data.id}
                  onClick={() => {
                    handleSelectedOption(data);
                  }}
                  style={{
                    background: index === idx && "#cfcfcf"
                  }}
                >
                  <div>{getTitle(data)}</div>
                </AutoSearchData>
              )
            })}
          </AutoSearchWrap>
        </AutoSearchContainer>
      )
      }
    </SearchContainer >
  );
}

export default AutoCompleteInput;
