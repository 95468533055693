import React, { useEffect, useState } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPLogManager from "../../features/HTTPLogManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LogItem from "./LogItem";

export default function LogWindow() {
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const [logList, setLogList] = useState([]);
  const [logFilterList, setLogFilterList] = useState([]);

  const [searchText, setSearchText] = useState("");

  async function fetchData() {
    let res = await HTTPLogManager.getLogList({
      accessToken: userInfo.accessToken
    });

    if (res !== undefined && res.data !== undefined) {
      let logList = res.data.result;
      // 최신순으로 정렬
      logList.sort((a, b) => {
        if (new Date(a.timestamp.replace(" ", "T")) > new Date(b.timestamp.replace(" ", "T")))
          return -1;
        if (new Date(a.timestamp.replace(" ", "T")) < new Date(b.timestamp.replace(" ", "T")))
          return 1;
      })

      setLogList(logList);
      setLogFilterList(logList);
    }
  }

  function searchList(findText) {
    let logFindList = logList.filter(item => (item.id === Number(findText) || item.event.includes(findText) || item.message.includes(findText)));
    setLogFilterList(logFindList);
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          fetchData();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  return (
    <div className="w-full bg-[white] mt-2 md:mt-14 px-2" style={{
      height: height - (width >= 768 ? 160 : 0),
    }}>
      {/* <div>
        width: {width} ~ height: {height}
      </div> */}
      <div className="w-full mt-[10px]" style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        <input
          autoComplete="off"
          className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
          placeholder={languageSet.roomingFindHint}
          type="text"
          name="search"
          onChange={(e) => {
            setSearchText(e.target.value);
            let findText = e.target.value;
            searchList(findText)
          }}
          value={searchText}
        />
      </div>
      <div className="w-full h-[100%] overflow-auto">
        {
          logFilterList.map(item => {
            return <LogItem key={item.id} data={item} />
          })
        }
      </div>
    </div>
  );
}